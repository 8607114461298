<template>
  <!-- Informations générales communes à toutes les pages -->
  <form @submit.prevent="onSubmit()">
    <div class="general-information-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Informations générales</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <KeyValue
            label="Type"
            :value="task?.type?.designation"
          />

          <Input id="designation" label="Désignation" />

          <Input
            id="date_previsionnelle"
            label="Date prévisionnelle"
            type="date"
            class="max-width-xxs"
          />

          <h5 class="color-gray">OU</h5>

          <SelectExtended
            id="stade_id"
            label="Stade prévisionnel"
            optionKey="id"
            optionValue="designation"
            apiEndpoint="dictionnaire/stade"
            :apiParams="stade.apiParams"
          />

          <Input
            id="date_realisation"
            label="Date de réalisation"
            type="date"
            class="max-width-xxs"

          />
          <Input id="observations" label="Observations" type="textarea" />
        </Container>
      </Section>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'GeneralInformationSubview',
  components: {
    KeyValue,
    Btn,
    Container,
    Section,
    Input,
    SelectExtended,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {},
  },

  data() {
    const nowDate = new Date(Date.now())
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().nullable(),
      date_previsionnelle: this.yup.date().nullable().transform((c, o) => (o === '' ? null : c)),
      stade_id: this.yup.object().nullable(),
      date_realisation: this.yup.date().nullable().max(nowDate, 'La date ne peut pas être supérieur à aujourd\'hui.').transform((c, o) => (o === '' ? null : c)),
      observations: this.yup.string().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      form: {
        experimentalTask: {},
      },
      essaiId: this.$route.params.id,
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },
      protocoleId: null,
      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
    }
  },

  mounted() {
    this.getProtocol()
    this.getFiche()
  },

  methods: {
    getProtocol() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocoleId = response.data.protocole.id
        this.fetchService.get(`protocole/${this.protocoleId}`).then((protocole) => {
          const cultures = protocole.data.cultures.map((item) => item.uid)
          if (cultures.length > 0) {
            this.stade.apiParams = {
              sort: 'ordre.ASC',
              filters: `culture.uid:in(${cultures})`,
            }
          }
        })
      })
    },

    getFiche() {
      this.emitter.emit('open-loader')

      this.schema = this.formService.populateShema(this.task)
      // eslint-disable-next-line no-param-reassign
      if (this.task.stade) {
        // eslint-disable-next-line no-param-reassign
        this.schema.stade_id = {
          key: this.task.stade.id,
          value: this.task.stade.designation,
        }
      }

      // eslint-disable-next-line no-param-reassign
      this.schema.date_previsionnelle = this.task?.date_previsionnelle?.split(' ').shift()
      // eslint-disable-next-line no-param-reassign
      this.schema.date_realisation = this.task?.date_realisation?.split(' ').shift()

      this.formService.setFormValues(this.schema)

      this.emitter.emit('close-loader')
    },

    handleSubmit(values) {
      if (typeof values.designation === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        values.designation = this.fiche.designation
      }
      // eslint-disable-next-line no-param-reassign
      values.stade_id = values?.stade_id?.key ?? null

      // eslint-disable-next-line no-param-reassign
      values.date_realisation = this.helperService.formatDateForApi(
        values?.date_realisation,
      )
      // eslint-disable-next-line no-param-reassign
      values.date_previsionnelle = this.helperService.formatDateForApi(
        values?.date_previsionnelle,
      )

      this.fetchService
        .put(`essai/${this.essaiId}/evenement/${this.task.id}`, values)
        .then(() => {
          this.taskService.handleExperimentalTaskRedirect(this.$route)
        })
        .catch((responseError) => {
          this.formService.handleApiError(responseError)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
